@import "./auxs";

/*! purgecss start ignore */
@media (min-width: calc-rel(2560px, $rfs-breakpoint-unit)) {
    html {
        font-size: 18px;
    }
}

/*! purgecss end ignore */


// Layout
html,
body,
#root,
.theme {
    // height: inherit;
    // @media (min-height: 29em) {
    height: 100%;
    overflow: hidden;
    // }
}

#root {
    display: flex;
    flex-direction: column;
}

// .theme{
/* width */
::-webkit-scrollbar {
    width: $border-radius + $border-width;
}

/* Track */
::-webkit-scrollbar-track {
    background: $stage;
    border-radius: division($border-radius, 2);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $common;
    border-radius: division($border-radius, 4);
    // border: $border-width solid $stage;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $common;
}

// }

.theme {
    display: flex;
    flex-direction: column;
}

.layout {
    height: inherit;
    display: inherit;
}

.view,
.wrapper {
    flex-direction: column;
    display: flex;
}

.wrapper {
    height: 100%;
    flex: 1;
    overflow: hidden;
    overflow-y: auto;
    z-index: 0;
}

.spinner {
    width: var(--#{$prefix}spinner-width);
    height: var(--#{$prefix}spinner-height);

    display: inline-block;
    background: currentColor;
    border-radius: var(--#{$prefix}spinner-border-radius);

    animation: var(--#{$prefix}spinner-animation-speed) linear infinite both var(--#{$prefix}spinner-animation-name);
}

.spinner-blink {
    $spinner-blink-size: calc-rel(8px);
    --#{$prefix}spinner-width: #{$spinner-blink-size};
    --#{$prefix}spinner-height: #{$spinner-blink-size};
    --#{$prefix}spinner-margin: -#{division($spinner-blink-size, 3)};
    --#{$prefix}spinner-animation-speed: 2s;
    // --#{$prefix}spinner-animation-name: spinner-grow;
    --#{$prefix}spinner-border-radius: #{$spinner-blink-size};
    margin: var(--#{$prefix}spinner-margin);
    position: absolute;
    opacity: .9;

    &:before,
    &:after {
        content: "";
        display: block;
        position: absolute;
        width: inherit;
        height: inherit;
        border-radius: inherit;
        background-color: inherit;
        animation: var(--#{$prefix}spinner-animation-speed) linear infinite both var(--#{$prefix}spinner-animation-name);
        transition: all 0.3s;
    }

    &:before {
        --#{$prefix}spinner-animation-speed: 2s;
        --#{$prefix}spinner-animation-name: spinner-blink;
        --#{$prefix}spinner-border-radius: inherit;
    }
}

@keyframes spinner-blink {
    50% {
        transform: scale(5);
        opacity: 0
    }

    100% {
        transform: scale(5);
        opacity: 0
    }
}

.spinner-loading {
    --#{$prefix}spinner-width: #{calc-rel(25px)};
    --#{$prefix}spinner-height: #{calc-rel(25px)};
    --#{$prefix}spinner-animation-speed: 0.75s;
    --#{$prefix}spinner-animation-name: spinner-grow;
    --#{$prefix}spinner-border-radius: #{$border-radius-sm};
}

.loading {
    position: relative;
    color: inherit;
    display: flex;
    justify-content: center;
}

.loading-sbx {
    width: calc-rel(40px);
    height: calc-rel(40px);
    display: inline-block;
    transform: rotate(45deg);

    .spinner-loading {
        width: unset;
        height: unset;
        animation: none;
        position: static;

        --#{$prefix}spinner-width: #{$spacer - $border-radius-sm};
        --#{$prefix}spinner-height: #{$spacer - $border-radius-sm};
        --#{$prefix}spinner-animation-speed: 4s;

        &:before,
        &:after {
            content: "";
            position: absolute;
            background: inherit;
            border-radius: $border-width * 1.5;
            animation: var(--#{$prefix}spinner-animation-speed) linear infinite var(--#{$prefix}spinner-animation-name);
        }

        &:nth-child(1) {

            &:before,
            &:after {
                width: 100%;
                height: var(--#{$prefix}spinner-height);
            }

            &:before {
                top: 0;
                right: 0;
                left: auto;
                --#{$prefix}spinner-animation-name: loading-sbxTop;
            }

            &:after {
                right: auto;
                bottom: 0;
                left: 0;
                --#{$prefix}spinner-animation-name: loading-sbxBottom;
                animation-delay: 1s;
            }
        }

        &:nth-child(2) {

            &:before,
            &:after {
                width: var(--#{$prefix}spinner-width);
                height: 100%;
            }

            &:before {
                top: auto;
                right: 0;
                bottom: 0;
                animation-delay: 0.5s;
                --#{$prefix}spinner-animation-name: loading-sbxRight;
            }

            &:after {
                top: 0;
                bottom: auto;
                left: 0;
                --#{$prefix}spinner-animation-name: loading-sbxLeft;
                animation-delay: 1.5s;
            }
        }
    }
}

@keyframes loading-sbxTop {
    0% {
        right: 0;
        left: auto;
        width: 100%;
    }

    12.5% {
        right: 0;
        left: auto;
        width: 0;
    }

    50% {
        right: auto;
        left: 0;
        width: 0;
    }

    62.5% {
        right: auto;
        left: 0;
        width: 100%;
    }

    100% {
        right: auto;
        left: 0;
        width: 100%;
    }
}

@keyframes loading-sbxBottom {
    0% {
        right: auto;
        left: 0;
        width: 100%;
    }

    12.5% {
        right: auto;
        left: 0;
        width: 0;
    }

    50% {
        right: 0;
        left: auto;
        width: 0;
    }

    62.5% {
        right: 0;
        left: auto;
        width: 100%;
    }

    100% {
        right: 0;
        left: auto;
        width: 100%;
    }
}

@keyframes loading-sbxRight {
    0% {
        top: auto;
        bottom: 0;
        height: 100%;
    }

    12.5% {
        top: auto;
        bottom: 0;
        height: 0;
    }

    50% {
        top: 0;
        bottom: auto;
        height: 0;
    }

    62.5% {
        top: 0;
        bottom: auto;
        height: 100%;
    }

    100% {
        top: 0;
        bottom: auto;
        height: 100%;
    }
}

@keyframes loading-sbxLeft {
    0% {
        top: 0;
        bottom: auto;
        height: 100%;
    }

    12.5% {
        top: 0;
        bottom: auto;
        height: 0;
    }

    50% {
        top: auto;
        bottom: 0;
        height: 0%;
    }

    62.5% {
        top: auto;
        bottom: 0;
        height: 100%;
    }

    100% {
        top: auto;
        bottom: 0;
        height: 100%;
    }
}

.loading-squares {
    width: calc-rel(70px);
    height: calc-rel(70px);
    display: inline-block;

    .spinner-loading {
        display: block;
        position: absolute;
        --#{$prefix}spinner-animation-speed: 2s;
        --#{$prefix}spinner-animation-name: loading-squares;

        &:nth-child(1) {
            animation-delay: 0s;
        }

        &:nth-child(2) {
            animation-delay: -0.667s;
        }

        &:nth-child(3) {
            animation-delay: -1.33s;
        }
    }
}

@keyframes loading-squares {
    0% {
        top: 0;
        left: 0;
    }

    12.5% {
        top: 0;
        left: 50%;
    }

    25% {
        top: 0;
        left: 50%;
    }

    37.5% {
        top: 50%;
        left: 50%;
    }

    50% {
        top: 50%;
        left: 50%;
    }

    62.5% {
        top: 50%;
        left: 0;
    }

    75% {
        top: 50%;
        left: 0;
    }

    87.5% {
        top: 0;
        left: 0;
    }

    100% {
        top: 0;
        left: 0;
    }
}

.directory-nav,
.filter-nav {
    --sbx-filter-link-color: rgba(var(--sbx-primary-rgb), var(--#{$prefix}text-opacity, .8));
    --sbx-filter-link-bg: rgba(var(--sbx-white-rgb), var(--#{$prefix}bg-opacity, 1));
}


.directory-nav {
    .navbar-nav {
        display: none;
        padding-left: division(($spacer), 2);
        margin: 0;

        .nav-link {
            &:not(.active):not(:hover) {
                opacity: .666;
            }

            &:first-of-type {
                margin-top: division($spacer, 3);
            }

            &:last-of-type {
                margin-bottom: division($spacer, 3);
            }
        }
    }

    .nav-link {
        opacity: 1;
        position: relative;
        clear: both;
        line-height: 1;
        background-color: transparent;
        border-color: transparent;
        padding-left: division(($spacer), 2);
        border-radius: ($border-radius);

        &:hover {
            opacity: 1;
            background-color: $common;
        }

        &:focus {
            box-shadow: none !important;
            outline: none !important;
        }

        &.active {
            opacity: 1;
            color: inherit;
            background-color: $white;
            box-shadow: 0 (($spacer * 1.5) - $border-width) ($spacer * 8) 0 rgba($dark, 0.095);

            .icns,
            .material-symbols-outlined,
            .material-symbols-rounded {
                color: var(--sbx-filter-link-color);
            }
        }

        &:not(.collapsed)>.ms-n1 {
            transform: rotate(90deg);
            transition: all 400ms ease-in-out;
        }

        // &.collapsed .ms-n1 {
        // 	transform: rotate(-90deg);
        // 	transition: all 400ms ease-in-out;
        // }
    }

    .open {
        opacity: 1;

        >.nav-link {
            display: block !important;
            padding-right: $spacer;
        }

        >.nav {
            display: block;
        }
    }
}

.filter-nav {
    .navbar-nav .nav-link {
        margin-top: - division($spacer, 2);

        .icns {
            border-radius: $border-radius;
            padding: division($spacer, 4);
        }

        &.active .icns {
            opacity: 1;
            color: var(--sbx-filter-link-color);
            background-color: var(--sbx-filter-link-bg);
            box-shadow: 0 (($spacer * 1.5) - $border-width) ($spacer * 8) 0 rgba($dark, 0.095);
        }
    }
}

.nav-link {
    position: relative;

    &:not(.collapsed)>.icn-expand {
        transform: rotate(180deg);
        transition: all 400ms ease-in-out;
    }

    &.active .spinner-blink {
        display: none;
    }
}

.react-pdf__Page__canvas {
    margin: auto;
    width: 100% !important;
    height: 100% !important;
}