// === External Library ===
@use './auxs' as *;
@use 'bootstrap/scss/bootstrap.scss' with (
	$enable-negative-margins:			$enable-negative-margins,
	$prefix:							$prefix,
	// colors
	$gray-100:                          $gray-100,
	$gray-200:                          $gray-200,
	$gray-300:                          $gray-300,
	$gray-400:                          $gray-400,
	$gray-500:                          $gray-500,
	$gray-600:                          $gray-600,
	$gray-700:                          $gray-700,
	$gray-800:                          $gray-800,
	$gray-900:                          $gray-900,
	$black:                             $black,
	$blue:                              $blue,
	$indigo:                            $indigo,
	$purple:                            $purple,
	$cyan:                              $cyan,
	$teal:                              $teal,
	$green:                             $green,
	$orange:                            $orange,
	$yellow:                            $yellow,
	$red:                               $red,
	$pink:                              $pink,
	$info:                              $info,
	$danger:                            $danger,
	$primary:                           $primary,
	$secondary:                         $secondary,
	$light:                             $gray-100,
	$dark:                              $gray-900,
	$body-color:                        $gray-800,
	$body-color-dark:                   $white,
	$theme-colors:                      $theme-colors,

	// Global
	$body-bg-dark:						$body-bg-dark,
	$body-secondary-bg-dark:			$body-secondary-bg-dark,
	$body-tertiary-bg-dark:				$body-tertiary-bg-dark,
	$min-contrast-ratio:				$min-contrast-ratio,
	$link-color:						$link-color,
	$link-hover-color:					$link-color,
	$component-active-bg:				$gray-800,
	$link-decoration:                   none,
	// $yiq-contrasted-threshold:          $yiq-contrasted-threshold,
	$border-color:                      $border-color,
	$border-radius:                     $border-radius,
	$border-radius-lg:                  $border-radius-lg,
	$border-radius-sm:                  $border-radius-sm,
	$table-color:                       currentColor,

	// Typography
	$font-family-base:                  $font-family-base,
	$headings-font-family:				$headings-font-family,

	$font-size-base:                    $font-size-base,
	$font-size-lg:                      $font-size-lg,
	$font-size-sm:                      $font-size-sm,

	$line-height-base:                  $line-height-base,
	$line-height-lg:                    $line-height-lg,
	$line-height-sm:                    $line-height-sm,

	$spacers:							$spacers,
	// $hr-border-color:                   $common,

	// Forms + Button
	$input-btn-padding-y:               $input-btn-padding-y,
	$input-btn-padding-x:               $input-btn-padding-x,
	$input-btn-font-family:             $input-btn-font-family,
	$input-btn-font-size:               $input-btn-font-size,
	$input-btn-line-height:             $input-btn-line-height,

	$input-btn-padding-y-sm:            $input-btn-padding-y-sm,
	$input-btn-padding-x-sm:            $input-btn-padding-x-sm,
	$input-btn-font-size-sm:            $input-btn-font-size-sm,
	// $input-btn-line-height-sm:          $input-btn-line-height-sm,

	$input-btn-padding-y-lg:            $input-btn-padding-y-lg,
	$input-btn-padding-x-lg:            $input-btn-padding-x-lg,
	$input-btn-font-size-lg:            $input-btn-font-size-lg,
	// $input-btn-line-height-lg:          $input-btn-line-height-lg,

	$input-btn-border-width:            $input-btn-border-width,

	// Btns
	$btn-font-weight:					$input-btn-font-weight,
	$btn-border-radius:                 $btn-border-radius,
	$btn-border-radius-lg:              $btn-border-radius-lg,

	// Forms
	$input-bg:                          $input-bg,
	$input-font-weight:					$input-btn-font-weight,
	$input-group-addon-bg:              $input-group-addon-bg,
	// $input-border-color:                $input-border-color,
	// $input-focus-border-color:          $input-focus-border-color,
	$input-color:                       $input-color,
	$input-border-radius-lg:            $input-border-radius-lg,
	$form-feedback-icon-valid:          $form-feedback-icon-valid,
	$form-validation-states:            $form-validation-states,
	$form-check-input-border:			transparent,

	// Dropdowns
	$dropdown-border-color:             $border-color,
	$dropdown-padding-y:                0,
	$dropdown-item-padding-y:           $dropdown-item-padding-y,
	$dropdown-link-color:               $gray-600,
	$dropdown-border-radius:            $dropdown-border-radius,

	// Cards
	// $card-bg:                           $card-bg,
	$card-border-color:                 $card-border-color,
	$card-spacer-x:                     $card-spacer-x,
	$card-spacer-y:                     $card-spacer-x,
	$card-cap-bg:                       transparent,
	$card-border-radius:                $card-border-radius,

	// Navs
	$nav-link-font-size:				$font-size-aux,
	$nav-link-font-weight:				$nav-link-font-weight,

	// Navbar
	$navbar-padding-y:					map-get($spacers, 2),
	$navbar-dark-color:                 rgba($white, .65),
	$navbar-dark-hover-color:           $navbar-dark-hover-color,
	// $navbar-dark-active-color:          $white,
	$grid-gutter-width:                 $grid-gutter-width,
	$grid-breakpoints:                  $grid-breakpoints,
	$container-max-widths:              $container-max-widths,

	// Modals
	$modal-content-border-radius:       0,
	$modal-content-border-color:        $modal-header-border-color,
	$modal-footer-border-color:        	$modal-header-border-color,
	$modal-header-border-color:			$modal-header-border-color,
	$modal-content-color:				$modal-content-color,
	$modal-backdrop-bg:                 $modal-backdrop-bg,
	$modal-backdrop-opacity:            $modal-backdrop-opacity,
	$modal-sm:							378px,
	$utilities:							$utilities,
	$offcanvas-backdrop-bg:				transparent,
	$offcanvas-box-shadow:				$box-shadow-lg,
);

:root{
	--#{$prefix}font-family-base: #{$font-family-base};
	--#{$prefix}font-family-headings: #{$headings-font-family};
}

:root{
	font-variant-ligatures: common-ligatures;
	font-smooth: always;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
	font-feature-settings: 'liga';
	-webkit-font-feature-settings: 'liga';
	-moz-font-feature-settings: 'liga';
}

.db,
.block{
	display: block;
}

.block{
	position: relative;
	@include clearfix;
}

.no-focus{
	box-shadow: none !important;
	outline: none !important;
}

.sr-only{
	@extend .visually-hidden;
}

@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);

		.no#{$infix}-event{
			user-select: none !important;
			pointer-events: none !important;
		}
		// line-height
		.lh#{$infix}-0 						{ line-height: 0;}
		.lh#{$infix}-copy					{ line-height: $line-height-copy !important;}
		.lh#{$infix}-title					{ line-height: $headings-line-height !important;}
		.lh#{$infix}-root					{ line-height: $font-size-base!important;}
		.lh#{$infix}-base                   { line-height: $line-height-base !important;}
		.lh#{$infix}-inherit				{ line-height: inherit !important;}
		.lh#{$infix}-unset					{ line-height: unset !important;}

		.fw#{$infix}-black					{ font-weight: 900 !important;}
		.fw#{$infix}-demi					{ font-weight: 600 !important;}
		.fw#{$infix}-medium					{ font-weight: 500 !important;}
		.fw#{$infix}-book					{ font-weight: 400 !important;}
		.fw#{$infix}-thin 					{ font-weight: 200 !important;}

		.fs#{$infix}-tiny    				{ font-size: 85%;}
		.fs#{$infix}-xs    					{ font-size: calc-rel(10px);}
		.fs#{$infix}-sm    					{ font-size: calc-rel(12px);}
		.fs#{$infix}-md    					{ font-size: calc-rel(13px);}
		.fs#{$infix}-aux					{ font-size: $font-size-aux;}
		.fs#{$infix}-book    				{ font-size: $font-size-book;}
		.fs#{$infix}-lg    					{ font-size: $font-size-lg;}
		.fs#{$infix}-xl    					{ font-size: $font-size-xl;}
		.fs#{$infix}-hd    					{ font-size: $font-size-base * 1.75;}
		.fs#{$infix}-root  					{ font-size: $font-size-base !important;}
		.fs#{$infix}-initial 				{ font-size: initial !important;}
		.fs#{$infix}-inherit 				{ font-size: inherit !important;}

		.ff#{$infix}-base					{ font-family: var(--#{$prefix}body-font-family) !important;}
		.ff#{$infix}-aux					{ font-family: var(--#{$prefix}auxs-font-family) !important;}
		
		.container#{$infix}-tiny 										{ max-width: calc-rel(320px, em) !important;}
		.container#{$infix}-narrow 										{ max-width: calc-rel(380px, em) !important;}
		.container#{$infix}-base 										{ max-width: calc-rel(480px, em) !important;}
		.container#{$infix}-loose 										{ max-width: calc-rel(560px, em) !important;}
		.container#{$infix}-wide 										{ max-width: calc-rel(680px, em) !important;}
		.container#{$infix}-copy 										{ max-width: calc-rel(992px, em) !important;}
		.container#{$infix}-default 									{ max-width: calc-rel(1040px, em) !important;}
		.container#{$infix}-screen										{ max-width: calc-rel(1240px, em) !important;}

		.flex#{$infix}-column,
		.flex#{$infix}-row{
			display: flex;
			&:not(.sticky-top)				{ position: relative; }
			.col#{$infix},
			[class*="col#{$infix}-"]		{ position: relative; }
		}

		.flex#{$infix}-fit												{ flex: 0 1 auto!important;}
		.flex#{$infix}-inherit											{ flex-direction: inherit !important;}
		.d#{$infix}-unset 												{ display: unset !important;}
		.d#{$infix}-inherit 											{ display: inherit !important;}

		.show#{$infix} 													{ display: inherit !important; }
		.scroll#{$infix}-auto 											{ overflow-y: auto; overflow-x: hidden; height: 100%;}
	}
}

.offcanvas.offcanvas-all {
	box-shadow: $box-shadow-md !important;
	// height: auto !important;
	--sbx-offcanvas-height: 97vh;
	// margin-top: $spacer * 1.625;
	// margin-top: 2%;
	// top: 0;
	.h-calc{
		height: calc(100% - 4rem);
	}
}

// .offcanvas-backdrop.fade{
// 	backdrop-filter: blur(0.125rem);
// }

.btn {
	--#{$prefix}btn-hover-bg-image: linear-gradient(900deg, rgba(var(--#{$prefix}white-rgb), .066), rgba(var(--#{$prefix}white-rgb), .066));
	--#{$prefix}btn-focus-bg-image: linear-gradient(900deg, rgba(var(--#{$prefix}white-rgb), .044), rgba(var(--#{$prefix}white-rgb), .044));
	--#{$prefix}btn-active-bg-image: linear-gradient(900deg, rgba(var(--#{$prefix}white-rgb), .088), rgba(var(--#{$prefix}white-rgb), .088));
	&:hover {
		background-image: var(--#{$prefix}btn-hover-bg-image);
	}

	.btn-check:focus + &,
	&:focus {
		background-image: var(--#{$prefix}btn-focus-bg-image);
	}

	.btn-check:checked + &,
	.btn-check:active + &,
	&:active,
	&.active,
	.show > &.dropdown-toggle {
		background-image: var(--#{$prefix}btn-active-bg-image);
	}

	&:disabled,
	&.disabled {
		background-image: if($enable-gradients, none, null);
	}

} 

.dropdown-menu {
    // box-shadow: 0 0 ($grid-gutter-width * 2) rgba($dark, 0.1);
	max-height: calc-rel(320px, em);
	overflow: hidden auto;
}

@each $name, $color in $theme-colors-rgb {
	$contrast-color: if(contrast-ratio(map-get($theme-colors, $name)) > $min-contrast-ratio, var(--#{$prefix}white-rgb), var(--#{$prefix}dark-rgb));
	.text-bg-#{$name}-hover:hover {
		color: rgba(#{$contrast-color}, var(--#{$prefix}text-opacity, 1)) !important;
		background-color: rgba($color, var(--#{$prefix}bg-opacity, 1)) !important;
	}
}

.text-bg-source,
.text-bg-source-hover:hover {
	--#{$prefix}source: var(--#{$prefix}info-rgb);

    color: rgba(var(--#{$prefix}source), var(--#{$prefix}text-opacity, 1));
    background-color: rgba(var(--#{$prefix}source), var(--#{$prefix}bg-opacity, .10));
}

.text-bg-sbx,
.text-bg-sbx-hover:hover {
    color: color-contrast($sbx-primary) !important;
	background-image: linear-gradient(113deg,$sbx-secondary -5%,$sbx-sca 52%,$sbx-cube 105%);
	background-color: $sbx-primary !important;
	border: 0;
}

.text-bg-prime,
.text-bg-prime-hover:hover {
	$contrast-color: if(contrast-ratio($primary) > $min-contrast-ratio, var(--#{$prefix}white-rgb), var(--#{$prefix}dark-rgb));
	--#{$prefix}btn-hover-color: rgba(#{$contrast-color}, var(--#{$prefix}text-opacity, 1));
	color: rgba(#{$contrast-color}, var(--#{$prefix}text-opacity, 1)); 
	@if map-get($theme-colors, 'accent') != null {
		background-image: -webkit-gradient(linear, left top, right top, from(rgba(var(--#{$prefix}accent-rgb), var(--#{$prefix}bg-opacity, 1))), to(rgba(var(--#{$prefix}secondary-rgb), var(--#{$prefix}bg-opacity, 1))));
		background-image: linear-gradient( 113deg , rgba(var(--#{$prefix}accent-rgb), var(--#{$prefix}bg-opacity, 1)) -5%, rgba(var(--#{$prefix}primary-rgb), var(--#{$prefix}bg-opacity, 1)) 52%, rgba(var(--#{$prefix}secondary-rgb), var(--#{$prefix}bg-opacity, 1)) 105%);		background-color: rgb(var(--#{$prefix}secondary-rgb), var(--#{$prefix}bg-opacity, 1));
	}
	@else {
		background-image: -webkit-gradient(linear, left top, right top, from(rgba(var(--#{$prefix}secondary-rgb), var(--#{$prefix}bg-opacity, 1))), to(rgba(var(--#{$prefix}primary-rgb), var(--#{$prefix}bg-opacity, 1))));
		background-image: linear-gradient( 113deg , rgba(var(--#{$prefix}secondary-rgb), var(--#{$prefix}bg-opacity, 1)) -5%, rgba(var(--#{$prefix}primary-rgb), var(--#{$prefix}bg-opacity, 1)) 105%);
	}
	background-color: rgb(var(--#{$prefix}primary-rgb), var(--#{$prefix}bg-opacity, 1)) !important;
	border: 0;
}

.modal-backdrop { display: none !important;}
.modal {
	// scss-docs-start modal-backdrop-css-vars
	--#{$prefix}backdrop-zindex: #{$zindex-modal-backdrop};
	--#{$prefix}backdrop-bg: var(--#{$prefix}#{$modal-backdrop-bg}-rgb);
	--#{$prefix}backdrop-opacity: #{$modal-backdrop-opacity};
	// scss-docs-end modal-backdrop-css-vars
  
	z-index: var(--#{$prefix}backdrop-zindex);
	background-color: rgba(var(--#{$prefix}backdrop-bg), var(--#{$prefix}backdrop-opacity, .85));
	.modal-dialog {
		width: var(--#{$prefix}modal-width);
		max-width: 100%;
	}
}
